import React, { useReducer, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";

import { clearError } from "@ROM/WarehouseInventories/actions";
import { canUpdateWarehouseInventories } from "@ROM/WarehouseInventories/permissions";
import { selectLoading, selectError, selectWarehouses } from "@ROM/WarehouseInventories/selectors";

import Button from "../common/Button";
import ListRow from "./ListRow";
import MessageAlert from "./MessageAlert";
import { calculateInitialState, prepareToSend, warehouseInventoriesReducer } from "./utils";

const List = ({ currentUser, warehouseInventories, products, onInventoryUpdate }) => {
  // const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const minimumDate = warehouseInventories
    .map((item) => item.attributes.updatedAt)
    .reduce((minimum, current) => (current < minimum ? current : minimum), new Date().toISOString());

  const inventoriesDate = new Intl.DateTimeFormat("en-US").format(new Date(minimumDate));

  const warehouses = useSelector(selectWarehouses)

  const initialState = calculateInitialState(warehouseInventories, products);
  const [inventories, dispatch] = useReducer(warehouseInventoriesReducer, initialState);
  const [editMode, setEditMode] = useState(false);
  const [message, setMessage] = useState(null);

  const sendUpdate = async () => {
    await onInventoryUpdate(prepareToSend(inventories));
    setEditMode(false);
    setMessage(error ? "Failed to update, try again later..." : "All inventories for this warehouse were updated successfully");
  };

  const switchToEditMode = () => {
    dispatch(clearError());
    setMessage(null);
    setEditMode(true);
  };

  const cancelUpdate = () => {
    setEditMode(false);
    dispatch({ type: "reset", initialState });
  };

  const onPalletsUpdate = (inventory) => (value) => {
    dispatch({ inventoryId: inventory.id, type: "palletsUpdate", pallets: value });
  };
  const onUnitsUpdate = (inventory) => (value) => dispatch({ inventoryId: inventory.id, type: "unitsUpdate", units: value });

  return (
    <div className="table-responsive p-3 ">
      <div className="small text-end mb-3">
        {canUpdateWarehouseInventories(currentUser) && (
          <Button disabled={editMode} onClick={switchToEditMode}>
            Edit Warehouse Inventory
          </Button>
        )}
        {!editMode && (
        // <div className="text-end">
        //   <a className="btn btn-primary" href="/downloads/warehouse_inventories">
        //     Download <i className="fas fa-file-excel" />
        //   </a>
        // </div>
        <div class="btn-group z-3 ms-3">
          <a type="button" class="btn btn-primary" href="/downloads/warehouse_inventories">Download All</a>
          <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
            <span class="visually-hidden">Toggle Dropdown</span>
          </button>
          <ul className="dropdown-menu z-3">
            {warehouses.map((warehouse) => {
              return (<li key={warehouse.id}>
                        <a 
                          class="dropdown-item" 
                          href={`/downloads/warehouse_inventories?warehouse_id=${warehouse.id}&warehouse_name=${warehouse.attributes.name}`}
                        >
                          Download {warehouse.attributes.name}
                        </a>
                      </li>)
            })}
          </ul>
        </div>
      )}
      </div>
      <MessageAlert error={error}>{message || error}</MessageAlert>
      <StyledTable className="table table-borderless table-sm text-black-50 small">
        <thead>
          <tr>
            <th className="text-nowrap">Product Name</th>
            <th className="text-nowrap">Packaging</th>
            <th className="text-nowrap text-end">{`Pallets as of ${inventoriesDate}`}</th>
            <th className="text-nowrap text-end">{`Units as of ${inventoriesDate}`}</th>
            <th className="text-nowrap text-end">Current Pallets</th>
            <th className="text-nowrap text-end pe-3">Current Units</th>
          </tr>
        </thead>
        <tbody>
          {inventories.filter((item)=>!item.product.categoryType.includes("Turf")).map((inventory) => (
            <ListRow
            editing={editMode}
            key={inventory.id}
            warehouseInventory={inventory}
            onPalletsUpdate={onPalletsUpdate(inventory)}
            onUnitsUpdate={onUnitsUpdate(inventory)}
            />
          ))}
          {inventories.filter((item)=>item.product.categoryType.includes("Turf")).map((inventory) => (
            <ListRow
              editing={editMode}
              key={inventory.id}
              warehouseInventory={inventory}
              onPalletsUpdate={onPalletsUpdate(inventory)}
              onUnitsUpdate={onUnitsUpdate(inventory)}
            />
          ))}
        </tbody>
      </StyledTable>
      {editMode && (
        <div className="text-end small">
          <Button disabled={loading} color="white" backgroundColor="#198754" borderColor="#198754" onClick={() => sendUpdate()}>
            Update All
          </Button>
          <Button disabled={loading} color="white" backgroundColor="#dc3545" borderColor="#dc3545" onClick={cancelUpdate}>
            Cancel
          </Button>
        </div>
      )}
      {warehouseInventories.length === 0 && <h5 className="text-center text-secondary">No inventory found</h5>}
      
    </div>
  );
};

const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 5px;
`;

List.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  warehouseInventories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onInventoryUpdate: PropTypes.func.isRequired,
};

export default List;
