import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import PointerSpan from "@ROM-ui/PointerSpan";
import Spinner from "@ROM-ui/Spinner";

import OrdersApi from "@ROM/Orders/api";
import { selectCurrentCompany } from "@ROM/Company/selectors";

import { createDeliveryPdf } from "../../utils";

const PdfActions = ({ deliveryNumber, deliveryItems, orderId, setError }) => {
  const [printing, setPrinting] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const company = useSelector(selectCurrentCompany);

  const buildPdf = async (setWait, deliveryItems, orderId, company, print) => {
    setWait(true);
    try {
      const { data } = await OrdersApi.find(orderId);
      const { data: order, included } = data;
      await createDeliveryPdf(deliveryNumber, deliveryItems, order, company, included, print);
    } catch (err) {
      console.error(err);
      setError(true);
    }
    setWait(false);
  };

  const printDelivery = (ev) => {
    ev.stopPropagation();
    buildPdf(setPrinting, deliveryItems, orderId, company, true);
  };

  const downloadDelivery = (ev) => {
    ev.stopPropagation();
    buildPdf(setDownloading, deliveryItems, orderId, company, false);
  };

  return (
    <>
      {downloading ? (
        <Spinner className="me-2" animation="border" variant="primary" size="sm" />
      ) : (
        <PointerSpan
          onClick={(ev) => downloadDelivery(ev)}
          className="fa fa-download text-primary me-xl-2 fa-fw "
          title="Download"
        />
      )}
      {printing ? (
        <Spinner className="" animation="border" variant="primary" size="sm" />
      ) : (
        <PointerSpan onClick={(ev) => printDelivery(ev)} className="fa fa-print text-primary fa-fw " title="Print" />
      )}
    </>
  );
};

PdfActions.propTypes = {
  deliveryNumber: PropTypes.number.isRequired,
  deliveryItems: PropTypes.array.isRequired,
  orderId: PropTypes.number.isRequired,
  setError: PropTypes.func.isRequired,
};

export default PdfActions;
